@import "../../soe-theme/src/scss/variables";

.container {
  margin: 40px;
  margin-bottom: 15px;
  width: 450px;
}

.submitButton {
  height: 31px;
  width: 106px;
}

.submitButtonContainer {
  margin-bottom: 26px;
  text-align: right;
}

.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 33px;
}

.workspace {
  display: grid;
  margin: 0;
  place-items: center center;
}
